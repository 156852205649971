var text = '#220011';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Lobster|Roboto+Mono',
  fonts: {
    body: 'Lobster, cursive',
    monospace: '"Roboto Mono", monospace'
  },
  colors: {
    text: text,
    background: 'tomato',
    primary: text
  }
};