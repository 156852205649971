module.exports = function (hljs) {
  var SWIFT_KEYWORDS = {
    keyword: '#available #colorLiteral #column #else #elseif #endif #file ' + '#fileLiteral #function #if #imageLiteral #line #selector #sourceLocation ' + '_ __COLUMN__ __FILE__ __FUNCTION__ __LINE__ Any as as! as? associatedtype ' + 'associativity break case catch class continue convenience default defer deinit didSet do ' + 'dynamic dynamicType else enum extension fallthrough false fileprivate final for func ' + 'get guard if import in indirect infix init inout internal is lazy left let ' + 'mutating nil none nonmutating open operator optional override postfix precedence ' + 'prefix private protocol Protocol public repeat required rethrows return ' + 'right self Self set static struct subscript super switch throw throws true ' + 'try try! try? Type typealias unowned var weak where while willSet',
    literal: 'true false nil',
    built_in: 'abs advance alignof alignofValue anyGenerator assert assertionFailure ' + 'bridgeFromObjectiveC bridgeFromObjectiveCUnconditional bridgeToObjectiveC ' + 'bridgeToObjectiveCUnconditional c contains count countElements countLeadingZeros ' + 'debugPrint debugPrintln distance dropFirst dropLast dump encodeBitsAsWords ' + 'enumerate equal fatalError filter find getBridgedObjectiveCType getVaList ' + 'indices insertionSort isBridgedToObjectiveC isBridgedVerbatimToObjectiveC ' + 'isUniquelyReferenced isUniquelyReferencedNonObjC join lazy lexicographicalCompare ' + 'map max maxElement min minElement numericCast overlaps partition posix ' + 'precondition preconditionFailure print println quickSort readLine reduce reflect ' + 'reinterpretCast reverse roundUpToAlignment sizeof sizeofValue sort split ' + 'startsWith stride strideof strideofValue swap toString transcode ' + 'underestimateCount unsafeAddressOf unsafeBitCast unsafeDowncast unsafeUnwrap ' + 'unsafeReflect withExtendedLifetime withObjectAtPlusZero withUnsafePointer ' + 'withUnsafePointerToObject withUnsafeMutablePointer withUnsafeMutablePointers ' + 'withUnsafePointer withUnsafePointers withVaList zip'
  };
  var TYPE = {
    className: 'type',
    begin: "\\b[A-Z][\\w\xC0-\u02B8']*",
    relevance: 0
  }; // slightly more special to swift

  var OPTIONAL_USING_TYPE = {
    className: 'type',
    begin: "\\b[A-Z][\\w\xC0-\u02B8']*[!?]"
  };
  var BLOCK_COMMENT = hljs.COMMENT('/\\*', '\\*/', {
    contains: ['self']
  });
  var SUBST = {
    className: 'subst',
    begin: /\\\(/,
    end: '\\)',
    keywords: SWIFT_KEYWORDS,
    contains: [] // assigned later

  };
  var STRING = {
    className: 'string',
    contains: [hljs.BACKSLASH_ESCAPE, SUBST],
    variants: [{
      begin: /"""/,
      end: /"""/
    }, {
      begin: /"/,
      end: /"/
    }]
  };
  var NUMBERS = {
    className: 'number',
    begin: '\\b([\\d_]+(\\.[\\deE_]+)?|0x[a-fA-F0-9_]+(\\.[a-fA-F0-9p_]+)?|0b[01_]+|0o[0-7_]+)\\b',
    relevance: 0
  };
  SUBST.contains = [NUMBERS];
  return {
    keywords: SWIFT_KEYWORDS,
    contains: [STRING, hljs.C_LINE_COMMENT_MODE, BLOCK_COMMENT, OPTIONAL_USING_TYPE, TYPE, NUMBERS, {
      className: 'function',
      beginKeywords: 'func',
      end: '{',
      excludeEnd: true,
      contains: [hljs.inherit(hljs.TITLE_MODE, {
        begin: /[A-Za-z$_][0-9A-Za-z$_]*/
      }), {
        begin: /</,
        end: />/
      }, {
        className: 'params',
        begin: /\(/,
        end: /\)/,
        endsParent: true,
        keywords: SWIFT_KEYWORDS,
        contains: ['self', NUMBERS, STRING, hljs.C_BLOCK_COMMENT_MODE, {
          begin: ':'
        } // relevance booster
        ],
        illegal: /["']/
      }],
      illegal: /\[|%/
    }, {
      className: 'class',
      beginKeywords: 'struct protocol class extension enum',
      keywords: SWIFT_KEYWORDS,
      end: '\\{',
      excludeEnd: true,
      contains: [hljs.inherit(hljs.TITLE_MODE, {
        begin: /[A-Za-z$_][\u00C0-\u02B80-9A-Za-z$_]*/
      })]
    }, {
      className: 'meta',
      // @attributes
      begin: '(@discardableResult|@warn_unused_result|@exported|@lazy|@noescape|' + '@NSCopying|@NSManaged|@objc|@objcMembers|@convention|@required|' + '@noreturn|@IBAction|@IBDesignable|@IBInspectable|@IBOutlet|' + '@infix|@prefix|@postfix|@autoclosure|@testable|@available|' + '@nonobjc|@NSApplicationMain|@UIApplicationMain)'
    }, {
      beginKeywords: 'import',
      end: /$/,
      contains: [hljs.C_LINE_COMMENT_MODE, BLOCK_COMMENT]
    }]
  };
};