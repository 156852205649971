var blue = '#00cdf1';
var black = '#003d48';
var primary = '#0800e3';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
  fonts: {
    body: '"Source Code Pro", monospace',
    monospace: '"Source Code Pro", monospace'
  },
  colors: {
    text: black,
    background: blue,
    primary: primary
  },
  styles: {
    pre: {
      color: 'background',
      bg: 'text'
    }
  }
};