'use strict';

require("core-js/modules/es6.regexp.constructor");

module.exports = jsExtras;
jsExtras.displayName = 'jsExtras';
jsExtras.aliases = [];

function jsExtras(Prism) {
  ;

  (function (Prism) {
    Prism.languages.insertBefore('javascript', 'function-variable', {
      'method-variable': {
        pattern: RegExp('(\\.\\s*)' + Prism.languages.javascript['function-variable'].pattern.source),
        lookbehind: true,
        alias: ['function-variable', 'method', 'function', 'property-access']
      }
    });
    Prism.languages.insertBefore('javascript', 'function', {
      method: {
        pattern: RegExp('(\\.\\s*)' + Prism.languages.javascript['function'].source),
        lookbehind: true,
        alias: ['function', 'property-access']
      }
    });
    Prism.languages.insertBefore('javascript', 'constant', {
      'known-class-name': [{
        // standard built-ins
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects
        pattern: /\b(?:(?:(?:Uint|Int)(?:8|16|32)|Uint8Clamped|Float(?:32|64))?Array|ArrayBuffer|BigInt|Boolean|DataView|Date|Error|Function|Intl|JSON|Math|Number|Object|Promise|Proxy|Reflect|RegExp|String|Symbol|(?:Weak)?(?:Set|Map)|WebAssembly)\b/,
        alias: 'class-name'
      }, {
        // errors
        pattern: /\b(?:[A-Z]\w*)Error\b/,
        alias: 'class-name'
      }]
    });
    Prism.languages.javascript['keyword'].unshift({
      pattern: /\b(?:as|default|export|from|import)\b/,
      alias: 'module'
    }, {
      pattern: /\bnull\b/,
      alias: ['null', 'nil']
    }, {
      pattern: /\bundefined\b/,
      alias: 'nil'
    });
    Prism.languages.insertBefore('javascript', 'operator', {
      spread: {
        pattern: /\.{3}/,
        alias: 'operator'
      },
      arrow: {
        pattern: /=>/,
        alias: 'operator'
      }
    });
    Prism.languages.insertBefore('javascript', 'punctuation', {
      'property-access': {
        pattern: /(\.\s*)#?[_$a-zA-Z\xA0-\uFFFF][$\w\xA0-\uFFFF]*/,
        lookbehind: true
      },
      'maybe-class-name': {
        pattern: /(^|[^$\w\xA0-\uFFFF])[A-Z][$\w\xA0-\uFFFF]+/,
        lookbehind: true
      },
      dom: {
        // this contains only a few commonly used DOM variables
        pattern: /\b(?:document|location|navigator|performance|(?:local|session)Storage|window)\b/,
        alias: 'variable'
      },
      console: {
        pattern: /\bconsole(?=\s*\.)/,
        alias: 'class-name'
      }
    }); // add 'maybe-class-name' to tokens which might be a class name

    var maybeClassNameTokens = ['function', 'function-variable', 'method', 'method-variable', 'property-access'];

    for (var i = 0; i < maybeClassNameTokens.length; i++) {
      var token = maybeClassNameTokens[i];
      var value = Prism.languages.javascript[token]; // convert regex to object

      if (Prism.util.type(value) === 'RegExp') {
        value = Prism.languages.javascript[token] = {
          pattern: value
        };
      } // keep in mind that we don't support arrays


      var inside = value.inside || {};
      value.inside = inside;
      inside['maybe-class-name'] = /^[A-Z][\s\S]*/;
    }
  })(Prism);
}