var white = '#fff';
var black = '#000';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Annie+Use+Your+Telescope',
  fonts: {
    body: '"Annie Use Your Telescope", cursive'
  },
  colors: {
    text: black,
    background: white
  },
  styles: {
    root: {
      textAlign: 'center'
    },
    pre: {
      textAlign: 'left'
    }
  }
};