'use strict';

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.array.map");

module.exports = parigp;
parigp.displayName = 'parigp';
parigp.aliases = [];

function parigp(Prism) {
  Prism.languages.parigp = {
    comment: /\/\*[\s\S]*?\*\/|\\\\.*/,
    string: {
      pattern: /"(?:[^"\\\r\n]|\\.)*"/,
      greedy: true
    },
    // PARI/GP does not care about white spaces at all
    // so let's process the keywords to build an appropriate regexp
    // (e.g. "b *r *e *a *k", etc.)
    keyword: function () {
      var keywords = ['breakpoint', 'break', 'dbg_down', 'dbg_err', 'dbg_up', 'dbg_x', 'forcomposite', 'fordiv', 'forell', 'forpart', 'forprime', 'forstep', 'forsubgroup', 'forvec', 'for', 'iferr', 'if', 'local', 'my', 'next', 'return', 'until', 'while'];
      keywords = keywords.map(function (keyword) {
        return keyword.split('').join(' *');
      }).join('|');
      return RegExp('\\b(?:' + keywords + ')\\b');
    }(),
    "function": /\w[\w ]*?(?= *\()/,
    number: {
      // The lookbehind and the negative lookahead prevent from breaking the .. operator
      pattern: /((?:\. *\. *)?)(?:\d(?: *\d)*(?: *(?!\. *\.)\.(?: *\d)*)?|\. *\d(?: *\d)*)(?: *e *[+-]? *\d(?: *\d)*)?/i,
      lookbehind: true
    },
    operator: /\. *\.|[*\/!](?: *=)?|%(?: *=|(?: *#)?(?: *')*)?|\+(?: *[+=])?|-(?: *[-=>])?|<(?:(?: *<)?(?: *=)?| *>)?|>(?: *>)?(?: *=)?|=(?: *=){0,2}|\\(?: *\/)?(?: *=)?|&(?: *&)?|\| *\||['#~^]/,
    punctuation: /[\[\]{}().,:;|]/
  };
}