'use strict';

require("core-js/modules/es6.array.for-each");

require("core-js/modules/es6.regexp.constructor");

module.exports = regex;
regex.displayName = 'regex';
regex.aliases = [];

function regex(Prism) {
  ;

  (function (Prism) {
    var specialEscape = {
      pattern: /\\[\\(){}[\]^$+*?|.]/,
      alias: 'escape'
    };
    var escape = /\\(?:x[\da-fA-F]{2}|u[\da-fA-F]{4}|u\{[\da-fA-F]+\}|c[a-zA-Z]|0[0-7]{0,2}|[123][0-7]{2}|.)/;
    var charClass = /\\[wsd]|\.|\\p{[^{}]+}/i;
    var rangeChar = '(?:[^\\\\-]|' + escape.source + ')';
    var range = RegExp(rangeChar + '-' + rangeChar); // the name of a capturing group

    var groupName = {
      pattern: /(<|')[^<>']+(?=[>']$)/,
      lookbehind: true,
      alias: 'variable'
    };
    var backreference = [/\\(?![123][0-7]{2})[1-9]/, // a backreference which is not an octal escape
    {
      pattern: /\\k<[^<>']+>/,
      inside: {
        'group-name': groupName
      }
    }];
    Prism.languages.regex = {
      charset: {
        pattern: /((?:^|[^\\])(?:\\\\)*)\[(?:[^\\\]]|\\[\s\S])*\]/,
        lookbehind: true,
        inside: {
          'charset-negation': {
            pattern: /(^\[)\^/,
            lookbehind: true
          },
          'charset-punctuation': /^\[|\]$/,
          range: {
            pattern: range,
            inside: {
              escape: escape,
              'range-punctuation': /-/
            }
          },
          'special-escape': specialEscape,
          charclass: charClass,
          backreference: backreference,
          escape: escape
        }
      },
      'special-escape': specialEscape,
      charclass: charClass,
      backreference: backreference,
      anchor: /[$^]|\\[ABbGZz]/,
      escape: escape,
      group: [{
        // https://docs.oracle.com/javase/10/docs/api/java/util/regex/Pattern.html
        // https://docs.microsoft.com/en-us/dotnet/standard/base-types/regular-expression-language-quick-reference?view=netframework-4.7.2#grouping-constructs
        // (), (?<name>), (?'name'), (?>), (?:), (?=), (?!), (?<=), (?<!), (?is-m), (?i-m:)
        pattern: /\((?:\?(?:<[^<>']+>|'[^<>']+'|[>:]|<?[=!]|[idmnsuxU]+(?:-[idmnsuxU]+)?:?))?/,
        inside: {
          'group-name': groupName
        }
      }, /\)/],
      quantifier: /[+*?]|\{(?:\d+,?\d*)\}/,
      alternation: /\|/
    };
    ['actionscript', 'coffescript', 'flow', 'javascript', 'typescript', 'vala'].forEach(function (lang) {
      var grammar = Prism.languages[lang];

      if (grammar) {
        grammar['regex'].inside = {
          'regex-flags': /[a-z]+$/,
          'regex-delimiter': /^\/|\/$/,
          'language-regex': {
            pattern: /[\s\S]+/,
            inside: Prism.languages.regex
          }
        };
      }
    });
  })(Prism);
}