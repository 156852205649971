var yellow = '#fd0';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto+Mono',
  fonts: {
    body: '"Roboto Condensed", system-ui, sans-serif',
    monospace: '"Roboto Mono", monospace'
  },
  colors: {
    text: '#000',
    background: yellow,
    primary: '#333'
  },
  text: {
    heading: {
      textTransform: 'uppercase'
    }
  },
  styles: {
    pre: {
      textAlign: 'left',
      color: 'background',
      bg: 'text'
    },
    code: {
      color: 'background',
      bg: 'text'
    }
  }
};