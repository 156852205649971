var white = '#fffceb';
var black = '#351e38';
var blue = '#2d5dd7';
export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Gloria+Hallelujah',
  fonts: {
    body: '"Gloria Hallelujah", cursive'
  },
  colors: {
    text: black,
    background: white,
    primary: blue
  }
};