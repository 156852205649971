var white = '#fff';
var black = '#000';
var red = '#f00';
export default {
  fonts: {
    body: '"Helvetica Neue", Helvetica, Arial, sans-serif'
  },
  colors: {
    text: black,
    background: white,
    primary: red
  },
  styles: {
    root: {
      textAlign: 'left'
    },
    Slide: {
      display: 'block',
      padding: '2em',
      textAlign: 'left'
    }
  }
};